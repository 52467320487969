import React, { useEffect, useState } from "react";
import { useParams, useNavigate  } from "react-router-dom";
// import "./CoursesPage.css"; // Assuming you have a separate CSS file for styling
import "./CourseDetails.css"

const CourseDetails = () => {
  const { id } = useParams();
  const [course, setCourse] = useState(null);
  const navigate = useNavigate();


  // Load course details from JSON file
  useEffect(() => {
    fetch("/courses.json")
      .then(response => response.json())
      .then(data => {
        const selectedCourse = data.find(c => c.id === parseInt(id));
        setCourse(selectedCourse);
      })
      .catch(error => console.error("Error loading course:", error));
  }, [id]);

  if (!course) {
    return <h2>Course not found</h2>;
  }
  const handlePurchase = () => {
   console.log('check', course.course_details.fees.international_students)
    navigate("/courseRegistrationForm", {
      state: {
        courseId: course.id,
        courseTitle: course.title,
        courseFeesLS: course.course_details.fees.local_students,
        courseFeesIS: course.course_details.fees.international_students,

      }
    });
  };

  return (
    <div className="course-details-container">
      <div className="course-header">
        <h1>{course.title}</h1>
        <h3>{course.organization}</h3>
        <p>{course.sumerize}</p>
      </div>

      <div className="course-section">
        <h2>Course Introduction</h2>
        <p>{course.introduction}</p>
      </div>

      <div className="course-section">
        <h2>Course Objectives</h2>
        <ul>
          {course.objectives.map((objective, index) => (
            <li key={index}>{objective}</li>
          ))}
        </ul>
      </div>

      <div className="course-section">
        <h2>Course Details</h2>
        <h5><strong>Duration:</strong> {course.course_details.duration}</h5>
        <h5><strong>Session Duration:</strong> {course.course_details.session_duration}</h5>
        <h5><strong>Timing:</strong> {course.course_details.timing}</h5>
        <h5><strong>Mode:</strong> {course.course_details.mode.join(", ")}</h5>
        <h5><strong>Certification:</strong> {course.course_details.certification}</h5>
        <h2>Fees</h2>
        <ul>
          <li><strong>International Students:</strong> {course.course_details.fees.international_students}</li>
          <li><strong>Local Students:</strong> {course.course_details.fees.local_students}</li>
        </ul>
       
      </div>

      <div className="course-section">
        <h2>Target Audience</h2>
        <ul>
          {course.target_audience.map((audience, index) => (
            <li key={index}>{audience}</li>
          ))}
        </ul>
      </div>

      <div className="course-section">
        <h2>Implementation Plan</h2>
        <ul><strong>Learning Model:</strong> {course.implementation_plan.learning_model}</ul>
        <h3>Training Methods:</h3>
        <ul>
          {course.implementation_plan.training_methods.map((method, index) => (
            <li key={index}>{method}</li>
          ))}
        </ul>
      </div>

      <div className="course-section">
        <h2>Course Schedule</h2>
        {course.course_schedule.map((day, index) => (
          <div key={index} className="schedule-day">
            <h3>{day.title}</h3>
            <h5><strong>Time:</strong> {day.time}</h5>
            
            <ul>
              {day.topics.map((topic, idx) => (
                <li key={idx}>{topic}</li>
              ))}
            </ul>
            <h5><strong>Activity:</strong> {day.activity}</h5>
            <h5><strong>Mode:</strong> {day.mode.join(", ")}</h5>
          </div>
        ))}
      </div>

      <div className="course-section">
        <h2>Learning Outcomes</h2>
        <ul>
          {course.learning_outcomes.map((outcome, index) => (
            <li key={index}>{outcome}</li>
          ))}
        </ul>
      </div>

      <button 
        className="purchase-btn" 
        onClick={handlePurchase}
      >
        Purchase Now
      </button>
    </div>
  );
};

export default CourseDetails;
