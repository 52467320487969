// import React, { useState, useEffect } from "react";
// import { useLocation, useNavigate } from "react-router-dom";
// import "./CoursesPage.css";

// const CourseRegistrationForm = () => {
//   const location = useLocation();
//   const navigate = useNavigate();
//   const course = location.state || {};

//   const [formData, setFormData] = useState({
//     fullName: "",
//     email: "",
//     phone: "",
//     nationality: "",
//     country: "",
//     modeOfLearning: "",
//     preferredSchedule: [],
//     hasExperience: "",
//     experienceDetails: "",
//     referralSource: "",
//     otherReferral: "",
//     questions: "",
//     agreeToUpdates: false,
//     courseName: course.courseTitle || "",
//     coursePrice: "",  // initially set to empty
//   });

//   useEffect(() => {
//     // Update price when nationality changes
//     const updatedPrice =
//       formData.nationality === "International"
//         ? course.courseFeesIS
//         : course.courseFeesLS;
//     setFormData((prevData) => ({ ...prevData, coursePrice: updatedPrice || "" }));
//   }, [formData.nationality, course.courseFeesIS, course.courseFeesLS]);

//   const handleNationalityChange = (e) => {
//     setFormData({ ...formData, nationality: e.target.value });
//   };

//   const handleChange = (e) => {
//     const { name, value, type, checked } = e.target;
//     setFormData({
//       ...formData,
//       [name]: type === "checkbox" ? checked : value,
//     });
//   };

//   const handleMultiSelect = (e) => {
//     const { name, value, checked } = e.target;
//     setFormData((prevData) => ({
//       ...prevData,
//       [name]: checked
//         ? [...prevData[name], value]
//         : prevData[name].filter((option) => option !== value),
//     }));
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     console.log("Form Submitted:", formData);
 
    
//     try {
//       await fetch("https://script.google.com/macros/s/AKfycbxwkbVr_VY2x6K5f4FtNSD4_bvYq1eeaNuKWI3xip2an9slkOnUx5ArNlxhQbOKVetd/exec", {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//         },
//         body: JSON.stringify(formData),
        
//       });
//       console.log("Request was successful!");
//     } catch (error) {
//       console.error("Error submitting form:", error);
//     }
//   };
//   // const handleSubmit = async (e) => {
//   //   e.preventDefault();
//   //   console.log("Form Submitted:", formData);
//   //   alert("Enrollment form submitted successfully!");
    
//   //   try {
//   //     await fetch("https://script.google.com/macros/s/AKfycbyqzVYh3bAdInimkxETYpKVhnmi6ZbeKqUwi99iyJ2a1xfqGDgUcpaihFq96FbQj-KM/exec", {
//   //       method: "POST",
//   //       headers: {
//   //         "Content-Type": "application/json",
//   //       },
//   //       body: JSON.stringify(formData),
//   // mode: "cors" 
//   //     });
//   //   } catch (error) {a
//   //     console.error("Error submitting form:", error);
//   //   }
//   // };

//   // OnePay Data for payment
//   const onePayData = {
//     appid: "80NR1189D04CD635D8ACD",
//     hashToken: "GR2P1189D04CD635D8AFD",
//     amount: parseFloat(formData.coursePrice),  // Updated to use the form data's price
//     orderReference: "7Q1M1187AE",
//     customerFirstName: formData.fullName.split(" ")[0],  // Get first name
//     customerLastName: formData.fullName.split(" ")[1] || "",  // Get last name if available
//     customerPhoneNumber: formData.phone,
//     customerEmail: formData.email,
//     transactionRedirectUrl: "https://www.google.com.au",  // Redirect after payment
//     additionalData: "vidula",
//     apptoken:
//       "ca00d67bf74d77b01fa26dc6780d7ff9522d8f82d30ff813d4c605f2662cea9ad332054cc66aff68.EYAW1189D04CD635D8B20"
//   };

//   useEffect(() => {
//     // Expose onePayData globally for use in the payment flow
//     window.onePayData = onePayData;

//     const handleOnePaySuccess = (e) => {
//       const successData = e.detail;
//       console.log("Payment SUCCESS from React:", successData);
//       // You could do additional logic here, e.g. POST to your server.
//     };

//     const handleOnePayFail = (e) => {
//       const failData = e.detail;
//       console.log("Payment FAIL from React:", failData);
//       // Additional logic or error handling
//     };

//     window.addEventListener("onePaySuccess", handleOnePaySuccess);
//     window.addEventListener("onePayFail", handleOnePayFail);

//     return () => {
//       // Cleanup event listeners when component unmounts
//       window.removeEventListener("onePaySuccess", handleOnePaySuccess);
//       window.removeEventListener("onePayFail", handleOnePayFail);
//     };
//   }, [onePayData]);

//   return (
//     <div className="container registration-container">
//       <h1>Enrollment Form for {formData.courseName || "Psychology Course"}</h1>
//       <p>Welcome to Helping Hand Psychological Hospital! Please fill out this form, and we’ll get back to you with more details.</p>

//       <form onSubmit={handleSubmit}>
//         <h2>Personal Information</h2>
//         <label>
//           Full Name:
//           <input type="text" name="fullName" value={formData.fullName} onChange={handleChange} required />
//         </label>
//         <label>
//           Email Address:
//           <input type="email" name="email" value={formData.email} onChange={handleChange} required />
//         </label>
//         <label>
//           Phone Number:
//           <input type="tel" name="phone" value={formData.phone} onChange={handleChange} required />
//         </label>

//         <label>
//           Nationality:
//           <select name="nationality" value={formData.nationality} onChange={handleNationalityChange} required>
//             <option value="" disabled>Select Nationality</option>
//             <option value="National">Sri Lankan</option>
//             <option value="International">International</option>
//           </select>
//         </label>

//         <label>
//           Country of Residence:
//           <input type="text" name="country" value={formData.country} onChange={handleChange} required />
//         </label>

//         <h2>Course Details</h2>
//         <div>Course Name: <strong>{formData.courseName}</strong></div>

//         {/* Only show course fee once a nationality has been selected */}
//         {formData.nationality && formData.coursePrice && (
//           <div>Course Fee: <strong>{formData.coursePrice}</strong></div>
//         )}

//         <label>Preferred Mode of Learning:</label>
//         <div className="checkbox-group">
//           <label>
//             <input type="radio" name="modeOfLearning" value="Online" onChange={handleChange} />
//             <strong> Online</strong>
//           </label>
//           <label>
//             <input type="radio" name="modeOfLearning" value="In-Person" onChange={handleChange} />
//             <strong> In-Person</strong>
//           </label>
//           <label>
//             <input type="radio" name="modeOfLearning" value="Hybrid" onChange={handleChange} />
//             <strong> Hybrid</strong>
//           </label>
//         </div>

//         <label>Preferred Schedule:</label>
//         <div className="checkbox-group">
//           <label>
//             <input type="checkbox" name="preferredSchedule" value="Weekdays Morning" onChange={handleMultiSelect} />
//             <strong> Weekdays (Morning)</strong>
//           </label>
//           <label>
//             <input type="checkbox" name="preferredSchedule" value="Weekdays Evening" onChange={handleMultiSelect} />
//             <strong> Weekdays (Evening)</strong>
//           </label>
//           <label>
//             <input type="checkbox" name="preferredSchedule" value="Flexible" onChange={handleMultiSelect} />
//             <strong> Flexible</strong>
//           </label>
//         </div>

//         <label>Do you have prior experience in psychology?</label>
//         <div className="checkbox-group">
//           <label>
//             <input type="radio" name="hasExperience" value="Yes" onChange={handleChange} />
//             <div><strong className="experience-res">Yes</strong> </div>
//           </label>
//           <label>
//             <input type="radio" name="hasExperience" value="No" onChange={handleChange} />
//             <div><strong className="experience-res">No</strong> </div>
//           </label>
//         </div>

//         {formData.hasExperience === "Yes" && (
//           <label>Describe your experience:
//             <textarea name="experienceDetails" value={formData.experienceDetails} onChange={handleChange}></textarea>
//           </label>
//         )}

//         <h2>Additional Information</h2>
//         <label>
//           How did you hear about us?
//           <select name="referralSource" value={formData.referralSource} onChange={handleChange}>
//             <option value="">Select an option</option>
//             <option value="Social Media">Social Media</option>
//             <option value="Website">Website</option>
//             <option value="Referral">Referral</option>
//             <option value="Advertisement">Advertisement</option>
//             <option value="Other">Other</option>
//           </select>
//         </label>

//         {formData.referralSource === "Other" && (
//           <label>Please specify:
//             <input type="text" name="otherReferral" value={formData.otherReferral} onChange={handleChange} />
//           </label>
//         )}

//         <label>
//           Do you have any questions or special requests?
//           <textarea name="questions" value={formData.questions} onChange={handleChange}></textarea>
//         </label>

//         <label className="agree-checkbox">
//           <input
//             type="checkbox"
//             name="agreeToUpdates"
//             checked={formData.agreeToUpdates}
//             onChange={handleChange}
//           />
//           I agree to receive updates and promotions.
//         </label>
        
//         <div id="iframe-container"> <button type="submit">Perchase</button></div>
       
//       </form>
//     </div>
//   );
// };

// export default CourseRegistrationForm;
// // AKfycbwZnBxILEFjsBYGeOhrKUBzP7gapFvwNiAr9rniKLZ_3kXGXE2euXwEAJSXtk7ximcC



import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./CoursesPage.css";

const CourseRegistrationForm = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const course = location.state || {};

  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    phone: "",
    nationality: "",
    country: "",
    modeOfLearning: "",
    preferredSchedule: [],
    hasExperience: "",
    experienceDetails: "",
    referralSource: "",
    otherReferral: "",
    questions: "",
    agreeToUpdates: false,
    courseName: course.courseTitle || "",
    coursePrice: "",
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState(null);
  const [submitSuccess, setSubmitSuccess] = useState(false);

  useEffect(() => {
    const updatedPrice =
      formData.nationality === "International"
        ? course.courseFeesIS
        : course.courseFeesLS;
    setFormData((prevData) => ({ ...prevData, coursePrice: updatedPrice || "" }));
  }, [formData.nationality, course.courseFeesIS, course.courseFeesLS]);

  const handleNationalityChange = (e) => {
    setFormData({ ...formData, nationality: e.target.value });
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleMultiSelect = (e) => {
    const { name, value, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: checked
        ? [...prevData[name], value]
        : prevData[name].filter((option) => option !== value),
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setSubmitError(null);
  
    try {
      // Use the /dev endpoint for testing
      const response = await fetch(
        "https://script.google.com/macros/s/AKfycbzAxMLor9zqQWMiKpn_zoxlnV6WUXQ8k4Cd5yNDZ16cEN3H_CRTeN_sF4FqHIGrT6wR/exec",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
          credentials: "include"
        }
      );
  
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      const data = await response.json();
      console.log("Success:", data);
      setSubmitSuccess(true);
      alert("Form submitted successfully!");
  
    } catch (error) {
      console.error("Error:", error);
      setSubmitError("Failed to submit form. Please try again later.");
    } finally {
      setIsSubmitting(false);
    }
  };

  // OnePay Data for payment
  const onePayData = {
    appid: "80NR1189D04CD635D8ACD",
    hashToken: "GR2P1189D04CD635D8AFD",
    amount: parseFloat(formData.coursePrice),
    orderReference: "7Q1M1187AE",
    customerFirstName: formData.fullName.split(" ")[0],
    customerLastName: formData.fullName.split(" ")[1] || "",
    customerPhoneNumber: formData.phone,
    customerEmail: formData.email,
    transactionRedirectUrl: "https://www.google.com.au",
    additionalData: "vidula",
    apptoken: "ca00d67bf74d77b01fa26dc6780d7ff9522d8f82d30ff813d4c605f2662cea9ad332054cc66aff68.EYAW1189D04CD635D8B20"
  };

  useEffect(() => {
    window.onePayData = onePayData;

    const handleOnePaySuccess = (e) => {
      const successData = e.detail;
      console.log("Payment SUCCESS from React:", successData);
    };

    const handleOnePayFail = (e) => {
      const failData = e.detail;
      console.log("Payment FAIL from React:", failData);
    };

    window.addEventListener("onePaySuccess", handleOnePaySuccess);
    window.addEventListener("onePayFail", handleOnePayFail);

    return () => {
      window.removeEventListener("onePaySuccess", handleOnePaySuccess);
      window.removeEventListener("onePayFail", handleOnePayFail);
    };
  }, [onePayData]);

  return (
    <div className="container registration-container">
      <h1>Enrollment Form for {formData.courseName || "Psychology Course"}</h1>
      <p>Welcome to Helping Hand Psychological Hospital! Please fill out this form, and we'll get back to you with more details.</p>

      {submitError && <div className="error-message">{submitError}</div>}
      {submitSuccess && <div className="success-message">Form submitted successfully!</div>}

      <form onSubmit={handleSubmit}>
        <h2>Personal Information</h2>
        <label>
          Full Name:
          <input type="text" name="fullName" value={formData.fullName} onChange={handleChange} required />
        </label>
        <label>
          Email Address:
          <input type="email" name="email" value={formData.email} onChange={handleChange} required />
        </label>
        <label>
          Phone Number:
          <input type="tel" name="phone" value={formData.phone} onChange={handleChange} required />
        </label>

        <label>
          Nationality:
          <select name="nationality" value={formData.nationality} onChange={handleNationalityChange} required>
            <option value="" disabled>Select Nationality</option>
            <option value="National">Sri Lankan</option>
            <option value="International">International</option>
          </select>
        </label>

        <label>
          Country of Residence:
          <input type="text" name="country" value={formData.country} onChange={handleChange} required />
        </label>

        <h2>Course Details</h2>
        <div>Course Name: <strong>{formData.courseName}</strong></div>

        {formData.nationality && formData.coursePrice && (
          <div>Course Fee: <strong>{formData.coursePrice}</strong></div>
        )}

        <label>Preferred Mode of Learning:</label>
        <div className="checkbox-group">
          <label>
            <input type="radio" name="modeOfLearning" value="Online" onChange={handleChange} />
            <strong> Online</strong>
          </label>
          <label>
            <input type="radio" name="modeOfLearning" value="In-Person" onChange={handleChange} />
            <strong> In-Person</strong>
          </label>
          <label>
            <input type="radio" name="modeOfLearning" value="Hybrid" onChange={handleChange} />
            <strong> Hybrid</strong>
          </label>
        </div>

        <label>Preferred Schedule:</label>
        <div className="checkbox-group">
          <label>
            <input type="checkbox" name="preferredSchedule" value="Weekdays Morning" onChange={handleMultiSelect} />
            <strong> Weekdays (Morning)</strong>
          </label>
          <label>
            <input type="checkbox" name="preferredSchedule" value="Weekdays Evening" onChange={handleMultiSelect} />
            <strong> Weekdays (Evening)</strong>
          </label>
          <label>
            <input type="checkbox" name="preferredSchedule" value="Flexible" onChange={handleMultiSelect} />
            <strong> Flexible</strong>
          </label>
        </div>

        <label>Do you have prior experience in psychology?</label>
        <div className="checkbox-group">
          <label>
            <input type="radio" name="hasExperience" value="Yes" onChange={handleChange} />
            <div><strong className="experience-res">Yes</strong> </div>
          </label>
          <label>
            <input type="radio" name="hasExperience" value="No" onChange={handleChange} />
            <div><strong className="experience-res">No</strong> </div>
          </label>
        </div>

        {formData.hasExperience === "Yes" && (
          <label>Describe your experience:
            <textarea name="experienceDetails" value={formData.experienceDetails} onChange={handleChange}></textarea>
          </label>
        )}

        <h2>Additional Information</h2>
        <label>
          How did you hear about us?
          <select name="referralSource" value={formData.referralSource} onChange={handleChange}>
            <option value="">Select an option</option>
            <option value="Social Media">Social Media</option>
            <option value="Website">Website</option>
            <option value="Referral">Referral</option>
            <option value="Advertisement">Advertisement</option>
            <option value="Other">Other</option>
          </select>
        </label>

        {formData.referralSource === "Other" && (
          <label>Please specify:
            <input type="text" name="otherReferral" value={formData.otherReferral} onChange={handleChange} />
          </label>
        )}

        <label>
          Do you have any questions or special requests?
          <textarea name="questions" value={formData.questions} onChange={handleChange}></textarea>
        </label>

        <label className="agree-checkbox">
          <input
            type="checkbox"
            name="agreeToUpdates"
            checked={formData.agreeToUpdates}
            onChange={handleChange}
          />
          I agree to receive updates and promotions.
        </label>
        
        <div id="iframe-container">
          <button type="submit" disabled={isSubmitting}>
            {isSubmitting ? "Submitting..." : "Purchase"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default CourseRegistrationForm;