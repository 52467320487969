import React, { useEffect, useState } from "react";
import "./PrivacyPolicy.css";

const PrivacyPolicy = () => {
  const [policy, setPolicy] = useState(null);

  useEffect(() => {
    fetch("/privacyPolicy.json")
      .then(response => response.json())
      .then(data => setPolicy(data))
      .catch(error => console.error("Error loading privacy policy:", error));
  }, []);

  if (!policy) {
    return <h2>Privacy Policy not found</h2>;
  }

  const { hospital, privacy_policy } = policy;

  return (
    <div className="privacy-policy-container">
      <h1>{policy.title}</h1>
      <div className="policy-section">
        <h2>Introduction</h2>
        <ul>{privacy_policy.introduction.statement}</ul>
      </div>
      <div className="policy-section">
        <h2>Refund Policy</h2>
        <h3>General Terms</h3>
        <ul>{privacy_policy.refund_policy.general_terms.no_refunds_after_registration}</ul>

        <h3>Refund Conditions</h3>
        <ul><strong>Institute Initiated Changes:</strong> {privacy_policy.refund_policy.refund_conditions.institute_initiated_changes}</ul>
        <ul><strong>Payment Transfer:</strong> {privacy_policy.refund_policy.refund_conditions.payment_transfer}</ul>

        <h3>Refund Request Process</h3>
        <ul><strong>Submission Email:</strong> {privacy_policy.refund_policy.refund_request_process.submission_email}</ul>
        <ul><strong>Processing Time:</strong> {privacy_policy.refund_policy.refund_request_process.processing_time}</ul>

        <h3>Additional Notes</h3>
        <ul>{privacy_policy.refund_policy.notes.map((note, index) => <li key={index}>{note}</li>)}</ul>
      </div>
      <div className="policy-section">
        <h2>Hospital Information</h2>
        <ul><strong>Name:</strong> {hospital.name}</ul>
        <ul><strong>Phone:</strong> {hospital.contact_info.phone}</ul>
        <ul><strong>Email:</strong> {hospital.contact_info.email}</ul>
      </div>

      

      <div className="policy-section">
        <h2>Information Collected</h2>
        <h3>Personal Information</h3>
        <ul>{privacy_policy.information_collected.personal_information.map((item, index) => <li key={index}>{item}</li>)}</ul>
        
        <h3>Academic Information</h3>
        <ul>{privacy_policy.information_collected.academic_information.map((item, index) => <li key={index}>{item}</li>)}</ul>

        <h3>Technical Information</h3>
        <ul>{privacy_policy.information_collected.technical_information.map((item, index) => <li key={index}>{item}</li>)}</ul>

        <h3>Payment Information</h3>
        <ul>{privacy_policy.information_collected.payment_information.map((item, index) => <li key={index}>{item}</li>)}</ul>
      </div>

      <div className="policy-section">
        <h2>Usage of Information</h2>
        <ul>{privacy_policy.usage_of_information.purposes.map((item, index) => <li key={index}>{item}</li>)}</ul>
      </div>

      <div className="policy-section">
        <h2>Information Sharing</h2>
        <ul>{privacy_policy.information_sharing.third_parties.map((item, index) => <li key={index}>{item}</li>)}</ul>
        <ul><strong>Note:</strong> {privacy_policy.information_sharing.note}</ul>
      </div>

      <div className="policy-section">
        <h2>Data Security</h2>
        <ul>{privacy_policy.data_security.measures.map((item, index) => <li key={index}>{item}</li>)}</ul>
      </div>

      <div className="policy-section">
        <h2>Student Rights</h2>
        <ul><strong>Access:</strong> {privacy_policy.student_rights.access}</ul>
        <ul><strong>Correction:</strong> {privacy_policy.student_rights.correction}</ul>
        <ul><strong>Deletion:</strong> {privacy_policy.student_rights.deletion}</ul>
        <ul><strong>Opt-Out:</strong> {privacy_policy.student_rights.opt_out}</ul>
      </div>

      <div className="policy-section">
        <h2>Retention Period</h2>
        <ul>{privacy_policy.retention_period.statement}</ul>
      </div>

      <div className="policy-section">
        <h2>Policy Changes</h2>
        <ul>{privacy_policy.policy_changes.statement}</ul>
      </div>


    </div>
  );
};

export default PrivacyPolicy;
