import React, { useEffect, useState } from "react";
import "./CoursesPage.css";
import { Link } from "react-router-dom";


const CoursesPage = () => {
  const [courses, setCourses] = useState([]);

  useEffect(() => {
    fetch("/courses.json")
      .then((response) => response.json())

      .then((data) => setCourses(data))

      .catch((error) => console.error("Error loading courses:", error));
  }, [courses]);

  return (
    <div className="courses-container">
      <h1>Psychology Courses</h1>
      <div className="courses-grid">
        {courses.map((course) => (
          <div key={course.id} className="course-card">
            <img
              src={require(`../../Assets/${course.image}`)}
              alt={course.title}
              className="course-image"
            />
            
            <div className="course-content">
              <h3 className="course-title">{course.title}</h3>

              <table class="course-details">
                <tr>
                  <td>
                    <strong>Duration:</strong>
                  </td>
                  <td>{course.course_details.duration}</td>
                </tr>

                <tr>
                  <td>
                    <strong>Timing:</strong>
                  </td>
                  <td>{course.course_details.timing}</td>
                </tr>
                <tr>
                  <td>
                    <strong>Local Students:</strong>
                  </td>
                  <td> {course.course_details.fees.local_students}</td>
                </tr>
                <tr>
                  <td>
                    <strong>International Students:</strong>
                  </td>
                  <td>{course.course_details.fees.international_students}</td>
                </tr>
              </table>

              <div className="course-sumery">
                <h6 className="course-sumery">{course.sumerize}</h6>

                <Link to={`/course/${course.id}`} className="purchase-link">
                  <button className="purchase-btn">View Course</button>
                </Link>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CoursesPage;
