import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./Navbar.css";
import logo from "../../Assets/logo-removebg-preview.png";

function Navbar() {
  const [isAdminLoggedIn, setIsAdminLoggedIn] = useState(false);
  const [educationDropdownOpen, setEducationDropdownOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('token');
    setIsAdminLoggedIn(!!token); 
  }, []);

  const handleLogout = () => {
    localStorage.removeItem('token');
    setIsAdminLoggedIn(false);
    navigate('/login', { replace: true });
  };

  const toggleEducationDropdown = () => {
    setEducationDropdownOpen(!educationDropdownOpen);
  };

  return (
    <div className="navbarClass">
      <nav className="navbar navbar-expand-lg navbar-light">
        <a className="navbar-brand" href="/">
          <img className="Logo" src={logo} alt="Logo" />
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarNavAltMarkup"
          aria-controls="navbarNavAltMarkup"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
          <div className="navbar-nav middleNavBar">
            <a className="nav-item nav-link" href="/">
              Home
            </a>
            <a className="nav-item nav-link" href="#service_section">
              Services
            </a>
            <a className="nav-item nav-link" href="#about_secs">
              About Us
            </a>
            <a className="nav-item nav-link" href="/aboutus">
              Our Team
            </a>
            <a className="nav-item nav-link" href="/CoursesPage">
              Courses
            </a>
           
            <div className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                href="#"
                id="navbarDropdownEducation"
                role="button"
                onClick={toggleEducationDropdown}
              >
                Education
              </a>
              <div
                className={`dropdown-menu ${educationDropdownOpen ? 'show' : ''}`}
                aria-labelledby="navbarDropdownEducation"
              >
                <a className="dropdown-item" href="/HigherDiploma">
                 HND
                </a>
                <a className="dropdown-item" href="/Diploma">
               Diploma
                </a>
                <a className="dropdown-item" href="/Certificate">
               Certificate Courses
                </a>
                <a className="dropdown-item" href="/Internships">
         Internships
                </a>
              </div>
            </div>
         
            <a className="nav-item nav-link mkAppointment" href="/main">
              Make Appointment
            </a>
           
          </div>
        </div>
        <button className="btn btn-primary my-2 my-sm-0 contactUsBtn">
          <a href="/main"> Make Appointment</a>
        </button>
      </nav>
    </div>
  );
}

export default Navbar;
