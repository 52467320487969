import React from "react";
import "./Footer.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <div id="footer_sec">
      <footer className="footer">
        <div className="footer_container">
          <div className="footer-col">
            <div className="footer-title">Our Info</div>
            <div>
              <div className="small-container-footer">
                <a href="#">Company Reg. No. : PV00243655</a>
              </div>
              <div className="small-container-footer">
                <a href="#">Health Ministry Reg. No. AA 14835</a>
              </div>
              <div className="small-container-footer">
                <a href="#">
                  Tertiary and Vocational Education Commission Registration
                  Number - P02/0481
                </a>
              </div>
              <div className="small-PrivacyPolicy">
                <a href="/PrivacyPolicy">
                 Privacy Policy
                </a>
              </div>

            </div>
          </div>
          <div className="footer-col">
            <div className="footer-title">Contact Us</div>
            <div>
              <div className="small-container-footer">
                <a href="tel:+940719001212">
                  <i className="fas fa-phone"></i>
                  071 900 1212
                </a>
              </div>
              <div className="small-container-footer">
                <a href="tel:+940117561122">
                  <i className="fas fa-phone"></i>
                  011 756 1122
                </a>
              </div>
              <div className="small-container-footer">
                <a href="mailto:thehelpinghandhospital18@gmail.com">
                  <i className="fas fa-envelope"></i>
                  thehelpinghandhospital18@gmail.com
                </a>
              </div>

              <div className="small-container-footer">
                <a
                  href="https://www.google.com/maps?q=7.20436, 79.84291"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="fas fa-map-marker"></i>
                  No. 102/A Taladuwa Rd, Negombo
                </a>
              </div>
            </div>

            <div className="social-links">
              <a
                href="https://www.facebook.com/profile.php?id=100063661026533&mibextid=aejMdD"
                target="_blank"
                rel="noopener noreferrer"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                >
                  <path d="M9 8H7v4h2v12h5V12h3.6l.4-4H14V5.5c0-.8.2-1.5 1.2-1.5H18V0h-3c-3.3 0-5 1.8-5 5v3z" />
                </svg>
              </a>
              <a
                href="https://www.tiktok.com/@the.helping.hand1?_t=8hINS6cz86r&_r=1"
                target="_blank"
                rel="noopener noreferrer"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                >
                  <path d="M12 2C13 6 15 8 18 8V11C16.5 11 15 10.5 14 9.5V16C14 20 10 22 7 20C4 18 4 15 4 14C4 11 6 8 9 8C9.8 8 10.8 8.2 11.5 8.5V12C11 11.5 10.5 11.2 9.8 11.2C8.2 11.2 7.5 12.5 7.5 14C7.5 16 9 17 10.5 16.5C11.5 16 12 15 12 14V2Z" />
                </svg>
              </a>
              <a
                href="https://www.instagram.com/helping_hand_hospital?igsh=MWx6cWl5YjA3YmJkMA=="
                target="_blank"
                rel="noopener noreferrer"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                >
                  <path d="M12 2C6.5 2 2 6.5 2 12S6.5 22 12 22 22 17.5 22 12 17.5 2 12 2ZM12 5C14.2 5 16 6.8 16 9C16 11.2 14.2 13 12 13S8 11.2 8 9C8 6.8 9.8 5 12 5ZM12 20C10.5 20 9 19.6 7.8 18.9 6.5 18.1 5.4 16.9 4.8 15.5 4.1 14 4 12.5 4 11 4 9.5 4.4 8 5 6.6 5.7 5.2 6.8 4 8.2 3.3 9.5 2.7 11 2.5 12.5 2.5 14 2.5 15.5 2.7 16.8 3.3 18.2 4 19.4 5.2 20.1 6.6 20.8 8 21.2 9.5 21.2 11 21.2 12.5 21 14 20.6 15.2 20 16.4 19.2 17.5 18.1 18.5 16.9 19.2 15.5 19.7 14 20 12.5 20 11 20ZM12 7C10.5 7 9 8.5 9 10S10.5 13 12 13 15 11.5 15 10 13.5 7 12 7Z" />
                </svg>
              </a>
              <a
                href="https://www.linkedin.com/company/the-helping-hand-psychological-hospital/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                >
                  <path d="M19 0h-14c-2.76 0-5 2.24-5 5v14c0 2.76 2.24 5 5 5h14c2.76 0 5-2.24 5-5v-14c0-2.76-2.24-5-5-5zm-11 19h-3v-9h3v9zm-1.5-10.28c-.97 0-1.75-.79-1.75-1.75s.79-1.75 1.75-1.75 1.75.79 1.75 1.75-.79 1.75-1.75 1.75zm13.5 10.28h-3v-4.99c0-1.19-.02-2.73-1.67-2.73-1.67 0-1.92 1.3-1.92 2.65v5.07h-3v-9h2.87v1.23h.04c.4-.76 1.39-1.56 2.86-1.56 3.06 0 3.63 2.01 3.63 4.62v4.71z" />
                </svg>
              </a>
            </div>
            
          </div>

          <div className="footer-col">
            <div className="footer-title">Our Branches</div>
            <div>
              <div className="small-container-footer">
                <a href="#">
                  Negombo Main Hospital -{" "}
                  <span className="footer-adress">
                    No. 102/A Taladuwa Rd, Negombo 11500{" "}
                  </span>
                </a>
              </div>
              <div className="small-container-footer">
                <a href="#">
                  Minuwangoda Center I –{" "}
                  <span className="footer-adress">
                    No 29, Watinapaha Rd, Dewalapola, Minuwangoda
                  </span>
                </a>
              </div>
              <div className="small-container-footer">
                <a href="#">
                  Minuwangoda Center II –{" "}
                  <span className="footer-adress">
                    N0108/C/S/RT Sunagara Rd, Pansilgoda, Minuwangoda
                  </span>
                </a>
              </div>
              <div className="small-container-footer">
                <a href="#">
                  Thalawathugoda Center –{" "}
                  <span className="footer-adress">
                    N068, Siripura Housing Complex, Hokandara Rd, Thalawathugoda
                  </span>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="copyright">
          <p>
            &copy; 2023 Helping Hands. All rights reserved. Powered by{" "}
            <a href="https://codemorphs.com/">Code Morphs</a>
          </p>
        </div>
      </footer>
    </div>
  );
}

export default Footer;
